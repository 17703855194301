<template>
    <div class="dr_write_wrap">
        <div class="dr_write_notice">{{ $t('notice_text') }}</div>
        <div class="notice_box mb29">
            <terms-component :terms-type="'lawsuit'" :terms-key="'lawsuit'"></terms-component>
        </div>
        <div class="dr_write_notice">{{ $t('warrant') }}</div>
        <div class="notice_box mb33">
            <terms-component :terms-type="'lawsuitRequest'" :terms-key="'lawsuitRequest'"></terms-component>
        </div>
        <div class="dr_write_notice mb11">{{ $t('request_form') }}</div>
        <div class="download_text">{{ $t('download_notice_text') }}</div>
        <button class="request_btn" @click="downloadSample()">
            <div class="download_img"></div>
            <span>{{ $t('request_form') }}.zip</span>
        </button>
        <div class="dr_write_notice">{{ $t('input_info') }}</div>
        <div class="info_box">
            <div class="info_text">{{ $t('creation_date') }}</div>
            <!--            <el-input class="info_input" type="text" placeholder="04/02/2021 14:52:37 +0000" v-model="date" @input="setData('creationDate',creationDate)"/>-->
            <el-date-picker
                    class="info_input"
                    v-model="createDate"
                    value-format="yyyy-MM-dd"
                    type="date"
                    @change="setData('createDate', createDate)"
                    placeholder="2021-04-02">
            </el-date-picker>
            <div class="info_text">{{ $t('creation_place') }}</div>
            <el-input class="info_input" type="text" placeholder="South of Korea" v-model="place"
                      @input="setData('place',place)"/>
            <div class="info_text">{{ $t('email') }}</div>
            <el-input class="info_input" type="email" placeholder="emailaddress@naver.com" v-model="email"
                      @input="setData('email',email)"/>
            <div class="info_text">{{ $t('phone1') }}</div>
            <el-input class="info_input" type="tel" placeholder="010-0000-0000" v-model="phone"
                      @input="setData('phone',phone)"/>
            <div class="info_text">{{ $t('proof_page') }}</div>
            <el-input class="info_input" type="text" v-model="proofUrl1" @input="inputUrl(1, proofUrl1)"/>
            <div class="info_text"></div>
            <el-input class="info_input" type="text" v-model="proofUrl2" :disabled="proofUrl1 === ''"
                      @input="inputUrl(2, proofUrl2)"/>
            <div class="info_text"></div>
            <el-input class="info_input" type="text" v-model="proofUrl3" :disabled="proofUrl2 === ''"
                      @input="inputUrl(3, proofUrl3)"/>
        </div>
        <div class="dr_write_notice">{{ $t('request_upload') }}</div>
        <div class="mb53" @click="changeType('requestForm')">

            <el-upload
                    v-show="requestForm.length === 0"
                    class="thumb_change_btn"
                    action=""
                    :limit="1"
                    :file-list="requestForm"
                    :auto-upload="false"
                    :show-file-list="false"
                    :on-change="handleEveryFileChange"
                    :on-remove="fileRemove"
                    :disabled="requestForm.length > 0"
            >
                <button class="request_btn" v-if="requestForm.length === 0">
                    <div class="download_img upload"></div>
                    <span>{{ $t('file_upload') }}</span>
                </button>
                <!--                <img v-else src="@/assets/image/main/change_item.png" alt=""
                                     style="width: 93px; height: 74px;">-->
            </el-upload>
            <div class="detection_filebox mb30" v-if="requestForm.length > 0">
                <div class="file_name" v-for="(file, index) in requestForm" :key="`requestForm${index}`"
                     @click="fileRemove(file, [], 'requestForm')">
                    <span>{{ file.name }}</span>
                    <button class="el-icon-close"></button>
                </div>
            </div>
        </div>
        <div class="dr_write_btn_wrap">
            <button class="write_btn" @click="runWrite">{{ $t('request1') }}</button>
            <br>
            <div class="red_text" v-show="false">{{ $t('all_input') }}</div>
            <div class="red_text" v-if="validation.hasError('createDate')">{{
                    validation.firstError('createDate')
                }}
            </div>
            <div class="red_text" v-else-if="validation.hasError('place')">{{ validation.firstError('place') }}</div>
            <div class="red_text" v-else-if="validation.hasError('email')">{{ validation.firstError('email') }}</div>
            <div class="red_text" v-else-if="validation.hasError('phone')">{{ validation.firstError('phone') }}</div>
            <div class="red_text" v-else-if="validation.hasError('proofUrl1')">{{
                    validation.firstError('proofUrl1')
                }}
            </div>
            <div class="red_text" v-else-if="validation.hasError('proofUrl2')">{{
                    validation.firstError('proofUrl2')
                }}
            </div>
            <div class="red_text" v-else-if="validation.hasError('proofUrl3')">{{
                    validation.firstError('proofUrl3')
                }}
            </div>
            <div class="red_text" v-else-if="validation.hasError('requestForm')">{{
                    validation.firstError('requestForm')
                }}
            </div>
        </div>
    </div>
</template>

<script>
import detectionValidator from "@/mixins/validators/detectionValidator";
import EventBus from "@/utils/event-bus";
import util from "@/mixins/util";
import elFileMixins from "@/mixins/elFileMixins";
import TermsComponent from "@/components/terms/TermsComponent";
import fileMixins from "@/mixins/fileMixins";
import awsS3FileDownload from "@/mixins/awsS3FileDownload";

export default {
    name: "DetectionWriteRequestLayout",
    mixins: [detectionValidator, elFileMixins, fileMixins,awsS3FileDownload],
    components: {TermsComponent},
    inject: ['detectionWriteSetData'],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {
            sampleFile: {},
        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.setDetectionImageKey();
        this.getSampleFile();
    },
    mounted() {
        EventBus.$on('DetectionWriteRequestLayoutValidator', this.runValidator)
    },
    beforeDestroy() {
        EventBus.$off('DetectionWriteRequestLayoutValidator')
    },
    destroyed() {
    },
    computed: {},
    methods: {
        setDetectionImageKey() {
            this.detectionImageKey = Number(this.$route.params.idx)
            this.setData('detectionImageKey', this.detectionImageKey);
        },
        setData(type, value) {
            this.detectionWriteSetData(type, value);
        },
        parentChange(type, value) {
            this.detectionWriteSetData(type, value);
        },
        inputUrl(cnt, value) {
            if (cnt === 1 && value === '') {
                this.proofUrl2 = "";
                this.setData('proofUrl2', this.proofUrl2)
                this.proofUrl3 = "";
                this.setData('proofUrl3', this.proofUrl3)
            } else if (cnt === 2) {
                this.proofUrl3 = "";
                this.setData('proofUrl3', this.proofUrl3)
            }
            this.setData(`proofUrl${cnt}`, value)
        },
        runValidator() {
            this.$validate(['createDate', 'place', 'email', 'phone', 'proofUrl1', 'proofUrl2', 'proofUrl3', 'requestForm'])
        },
        getSampleFile() {
            let params = {
                cid: 1,
                cty: 'lawsuitSample',
            }
            try {
                this.$api.$file.getFile(params).then(res => res.Data.Result).then(res => {
                    if (!util.isEmpty(res.List)) {
                        this.sampleFile = res.List[0];
                    }
                })
            } catch (e) {
                console.log(e)
            }
        },
        downloadSample() {
            if (util.isEmpty(this.sampleFile.a_idx)) {
                return false;
            }
            this.runDownloadFileInfo('lawsuitSample', this.sampleFile, '1', false).then(res => {
                if (!res) {
                    this.errorAlert(this.$t('file_download_error'));
                }
            })

        },
        returnBrowserName() {
            let userAgent = navigator.userAgent;
            let browserName = undefined;
            switch (true) {
                case /Trident|MSIE/.test(userAgent):
                    browserName = 'ie';
                    break;

                case /Edge/.test(userAgent):
                    browserName = 'edge';
                    break;

                case /Chrome/.test(userAgent):
                    browserName = 'chrome';
                    break;

                case /Safari/.test(userAgent):
                    browserName = 'safari';
                    break;

                case /Firefox/.test(userAgent):
                    browserName = 'firefox';
                    break;

                case /Opera/.test(userAgent):
                    browserName = 'opera';
                    break;

                default:
                    browserName = 'unknown';
            }
            return browserName;
        },
        runWrite() {
            EventBus.$emit('writeDetection')
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>w
